import React, {useState} from 'react';
import {trackEvent, trackScreenView} from '../functions/analytics';
import {FormField} from './FormField';
import FormCheckbox from './FormCheckbox';
import {UploadField} from './UploadField';
import deleteIcon from '../svg/delete.svg';
import axios from '../functions/axios.config';
import LoadingSpinner from '../svg/LoadingSpinner';
import Check from '../svg/Check';


const UploadForm = (props) => {

  const inputDefaults = {
    uploadFiles: [],
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    privacy: false,
  }

  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const inputChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.value
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'firstName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'lastName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'phone':
        if (typeof(value) === 'undefined' || value === null || !RegExp('^[0-9\\-\(\)\+\/ ]{7,25}$').test(value)) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      case 'privacy':
        if (typeof(value) === 'undefined' || value === null || value === false) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      //console.log(errors)
      trackScreenView('Risikoanalyse Validierungsfehler')
    } else {
      postMessage(input)
      trackScreenView('Risikoanalyse gesendet')
      //console.log('all OK')
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/risk',payload)
      .then ( response => {
        console.log('message sent')
        setSuccess(true)
        setLoading(false)
        return true
      })
      .catch(error => {
        console.log('API error')
        setSuccess(false)
        setLoading(false)
        return false
      });
  }

  const uploadSuccessHandler = (name, uuid) => {
    setInput({
      ...input,
      uploadFiles: [...input.uploadFiles, {name: name, uuid: uuid}],
    })
  }

  const removeUploadHandler = (uuid) => {
    console.log(input.uploadFiles)
    const newArray = input.uploadFiles.filter(i => i.uuid !== uuid)
    console.log(newArray)
    setInput({
      ...input,
      uploadFiles: newArray,
    })
  }

  const LoadingButton = () => {
    return(
      <button
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3 cursor-wait"
        type="button"
      >
        Jetzt Risikoanalyse anfordern<br/>
        <span className="font-normal text-xs">
          <div className="flex items-center justify-center text-center pt-1"><LoadingSpinner /></div>
        </span>
      </button>
    )
  }

  const SendButton = () => {
    return(
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3"
        type="submit"
      >
        Jetzt Risikoanalyse anfordern<br/>
        <span className="font-normal text-xs">kostenlos & unverbindlich</span>
      </button>
    )
  }

  const ReadyButton = () => {
    return(
      <button
        className="bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3 inline-flex cursor-default"
        type="button"
      >
        <Check /> Vielen Dank für Ihre Anfrage
      </button>
    )
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />

  return(
    <div className="w-full sm:w-2/3 md:w-full lg:w-2/3 xl:w-full">
      <form id="configuratorContactForm" onSubmit={handleSubmit} autoComplete="on" className="bg-gray-100 shadow-md rounded px-2 sm:px-8 pt-6 pb-8 mb-4 text-left">

        <UploadField
          uploadFieldId={'risikoanalyse'}
          uploadSuccessHandler={uploadSuccessHandler}
          apiUrl={'/api/upload/'}
          mimeType="image/*"
          text1="Foto hochladen"
          text2="Klick, Drag&Drop oder Handy Kamera"
        />

        <div id="photoList" className="mb-4">
          <ul className="list-disc text-sm">
            { input.uploadFiles.map(fileEntry => (
              <li className="flex" key={fileEntry.uuid}>
                <div className="rounded-md bg-indigo-100 flex p-2 m-1">
                  {fileEntry.name}
                  <img src={deleteIcon} className="w-5 ml-2 cursor-pointer hover:bg-white" alt="Datei löschen" onClick={() => removeUploadHandler(fileEntry.uuid)}/>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex justify-end text-sm text-gray-500 mb-4">*Pflichtfeld</div>

        {FormField({
          fieldId: 'firstName',
          fieldName:'Vorname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Vornamen an.'
        })}
        {FormField({
          fieldId: 'lastName',
          fieldName:'Nachname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Nachnamen an.'
        })}
        {FormField({
          fieldId: 'company',
          fieldName:'Firma',
          required: false,
          handlers: handlers,
        })}
        {FormField({
          fieldId: 'phone',
          fieldName:'Telefon',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige Telefonnummer an.'
        })}
        {FormField({
          fieldId: 'email',
          fieldName:'E-Mail',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
        })}
        {FormCheckbox({
          fieldId: 'privacy',
          fieldName: <div>Ja, ich stimme der <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.
            <br/>Ein Widerruf ist jederzeit möglich.</div>,
          handlers: handlers,
          errorText: 'Bitte bestätigen Sie die Datenschutzerklärung'
        })}

        <div className="flex items-center justify-end mt-8">
          {formButton}
        </div>

      </form>
    </div>
  )

}

export default UploadForm
